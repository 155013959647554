import React, {useEffect, useMemo} from 'react'
import {StyledWidgetizedScreen} from './WidgetizedScreen.styles'
import {PageMeta} from './WidgetizedScreen.types'
import MetaData from './MetaData'
import WidgetRenderer from 'src/mono/web-core/auditedWidgets/WidgetRenderer'
import {useAppConfigContext} from 'src/context/AppConfigContext'
import useScreenName from 'src/hooks/useScreenName/useScreenName'

interface WidgetizedScreenProps {
  data: {
    meta: PageMeta
    widgets?: any[]
    appConfig?: any
    topLeftWidgets?: any[]
    topRightWidgets?: any[]
  }
}

function AuditedWidgetizedScreen(props: WidgetizedScreenProps) {
  const {data} = props || {}

  const {
    appConfig,
    meta,
    widgets = [],
    topLeftWidgets,
    topRightWidgets,
  } = data || {}

  const [, appConfigContextActions] = useAppConfigContext()
  const {resetUpdateConfig, updateAppConfig} = appConfigContextActions
  const {screenName} = useScreenName()

  useEffect(() => {
    updateAppConfig(appConfig ?? {})
    return () => resetUpdateConfig()
  }, [appConfig, resetUpdateConfig, updateAppConfig])

  return (
    <>
      <MetaData meta={meta} />
      <StyledWidgetizedScreen>
        {(topLeftWidgets || topRightWidgets) && (
          <div className="grid-container">
            <div className="left-column">
              {topLeftWidgets && (
                <WidgetRenderer
                  widgets={topLeftWidgets}
                  pageName={screenName}
                />
              )}
            </div>
            <div className="right-column">
              {topRightWidgets && (
                <WidgetRenderer
                  widgets={topRightWidgets}
                  pageName={screenName}
                />
              )}
            </div>
          </div>
        )}
        <WidgetRenderer widgets={widgets} pageName={screenName} />
      </StyledWidgetizedScreen>
    </>
  )
}

export default AuditedWidgetizedScreen
