import styled from 'styled-components'

export const StyledWidgetContainer = styled.div`
  width: 100%;

  &.FLUID {
    width: 100%;
  }

  &.CONTAINED {
    width: 100%;
    padding: 0 var(--dls-size-16);
  }

  &.VERTICAL_TOP_SPACING_NONE {
    margin-top: 0;
  }
  &.VERTICAL_TOP_SPACING_COMPACT {
    margin-top: var(--dls-size-16);
  }
  &.VERTICAL_TOP_SPACING_GENEROUS {
    margin-top: var(--dls-size-24);
  }
  &.VERTICAL_TOP_SPACING_MINUTE {
    margin-top: var(--dls-size-8);
  }
  &.VERTICAL_BOTTOM_SPACING_NONE {
    margin-bottom: 0;
  }
  &.VERTICAL_BOTTOM_SPACING_MINUTE {
    margin-bottom: var(--dls-size-8);
  }
  &.VERTICAL_BOTTOM_SPACING_COMPACT {
    margin-bottom: var(--dls-size-24);
  }
  &.VERTICAL_BOTTOM_SPACING_GENEROUS {
    margin-bottom: var(--dls-size-32);
  }
  &.ADJUST_SPACING_FOR_BACKGROUND {
    padding-top: var(--dls-size-16);
    padding-bottom: var(--dls-size-16);
    border-radius: var(--dls-size-16);
  }
  &.ADJUST_SPACING_FOR_TOP_BORDER {
    padding-top: 0;
    border-radius: 0;
  }

  .widget-title-wrapper {
    width: 100%;
    text-align: center;
    margin-bottom: var(--dls-size-16);

    &[data-iscompactlyspaced='true'] {
      margin-bottom: var(--dls-size-4);
    }
  }

  .widget-subtitle-wrapper {
    width: 100%;
    padding: 0 var(--dls-size-16);
    text-align: center;
    margin-bottom: var(--dls-size-16);
  }

  .widget-header-container {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 786px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  .widget-header {
    display: flex;
    flex-direction: column;
    gap: var(--dls-size-16);
    margin-bottom: var(--dls-size-16);
    color: var(--dls-black-color);
    .PRIMARY {
      color: var(--dls-brand-primary-color-500);
    }
    .SECONDARY {
      color: var(--dls-secondary-text-colors);
    }
    .WHITE {
      color: var(--dls-white-color);
    }

    @media screen and (min-width: 786px) {
      max-width: 1120px !important;
      margin: auto;
      margin-bottom: var(--dls-size-16);
    }
  }

  .title-container {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;

    .title {
      flex-grow: 1;
    }

    .cta-container {
      margin-left: var(--dls-size-16);
    }

    .ctaText {
      color: var(--dls-brand-primary-color-500);
      cursor: pointer;
      white-space: nowrap;
    }
  }

  &.FLUID {
    .widget-header {
      padding-left: var(--dls-size-16);
      padding-right: var(--dls-size-16);
    }
  }

  &.SUPPORTED_FOR_DESKTOP {
    @media screen and (min-width: 768px) {
      max-width: 1124px !important;
      margin-left: auto;
      margin-right: auto;

      &.CONTAINED {
        width: 100%;
        padding: 0 var(--dls-size-32);
      }

      &.VERTICAL_TOP_SPACING_NONE {
        padding-top: 0;
      }
      &.VERTICAL_TOP_SPACING_COMPACT {
        padding-top: var(--dls-size-32);
      }
      &.VERTICAL_TOP_SPACING_GENEROUS {
        padding-top: var(--dls-size-48);
      }
      &.VERTICAL_BOTTOM_SPACING_NONE {
        padding-bottom: 0;
      }
      &.VERTICAL_BOTTOM_SPACING_COMPACT {
        padding-bottom: var(--dls-size-32);
      }
      &.VERTICAL_BOTTOM_SPACING_GENEROUS {
        padding-bottom: var(--dls-size-48);
      }
      &.ADJUST_SPACING_FOR_BACKGROUND {
        padding-top: var(--dls-size-24);
        padding-bottom: var(--dls-size-24);
        border-radius: var(--dls-size-24);
      }
      &.ADJUST_SPACING_FOR_TOP_BORDER {
        padding-top: 0;
        border-radius: 0;
      }
      &.DESKTOP_FULL_WIDTH {
        max-width: 100% !important;
      }
    }
  }
  &.SUPPORTED_ONLY_FOR_MOBILE {
    max-width: 500px !important;
    margin-left: auto;
    margin-right: auto;
  }
`

export const StyledBottomBorder = styled.div`
  width: 100%;
  height: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  .widget-bottom-border {
    width: 100%;
    height: 5px;
  }

  &[data-isonlysupportedformobile='true'] {
    max-width: 500px !important;
    margin: auto;
  }
`

export const StyledTopBorder = styled.div`
  margin-bottom: var(--dls-size-16) !important;
  display: flex;

  .widget-top-border {
    width: 100%;
    height: auto;
  }

  &[data-isonlysupportedformobile='true'] {
    max-width: 500px !important;
    margin: auto;
  }
`
