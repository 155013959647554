import styled from 'styled-components'

export const AccordionItemContainer = styled.div`
  padding-top: var(--dls-size-8);
  padding-bottom: var(--dls-size-12);

  @media (min-width: 768px) {
    padding: var(--dls-size-16) 0px;
  }

  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--dls-size-16);
  }

  .title-image-container {
    width: 56px;
    height: auto;
  }
  .title-image {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
  .title-text {
    margin-right: var(--dls-size-24);
    width: 90%;
  }

  .content-text {
    margin-top: 0;
    color: var(--dls-secondary-text-colors);
    height: 0;
    transition: height 0.3s ease-in-out, margin-top 0.3s ease-in-out;
    overflow: hidden;
    width: 90%;
  }

  .icon-container {
    display: inline-block;
    animation: none;
    transform: rotate(0deg);
    transition: transform 0.5s, opacity 0.3s;
    opacity: 1;
  }

  .icon-container.animating {
    animation: spin 0.5s ease-in-out forwards;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
      opacity: 1;
    }
    50% {
      transform: rotate(90deg);
      opacity: 0;
    }
  }

  &[data-isopen='true'] {
    .content-text {
      margin-top: var(--dls-size-16);
      height: auto;
    }
  }
`
