import Head from 'next/head'
import React, {useMemo} from 'react'
import {PageMeta} from './WidgetizedScreen.types'
import {useDeviceTypeContext} from '@web-components'
import {getOptimizedImageKitLink} from 'src/mono/web-components/components/atomic/OptimizedImage/getOptimizedImageLink'

interface MetaDataProps {
  meta: PageMeta
}
function MetaData({meta}: MetaDataProps) {
  const {
    canonical,
    description,
    noIndex,
    ogDescription,
    ogImage,
    ogStock,
    ogTitle,
    ogUrl,
    preloadImage,
    title,
    keywords,
    ogBrand,
    seo_meta = [],
  } = meta ?? {}

  const {isMobile} = useDeviceTypeContext()

  const modifiedPreloadImage = useMemo(() => {
    return (
      !!preloadImage &&
      getOptimizedImageKitLink(preloadImage, 'FULL', 'FULL', isMobile)
    )
  }, [isMobile, preloadImage])

  return (
    <Head>
      {noIndex && <meta name="robots" content="noindex" />}
      {title ? <title>{title}</title> : null}
      {description ? (
        <meta name="description" content={description}></meta>
      ) : null}
      {!!keywords && <meta name="keywords" content={keywords}></meta>}
      {canonical && <link rel="canonical" href={canonical} />}
      {/* OG:START */}
      {!!ogTitle && <meta property="og:title" content={ogTitle} />}
      {!!ogDescription && (
        <meta property="og:description" content={ogDescription} />
      )}
      {!!ogUrl && <meta property="og:url" content={ogUrl} />}
      {!!ogImage && <meta property="og:image" content={ogImage} />}
      {!!ogBrand && <meta property="product:brand" content={ogBrand} />}
      {!!ogStock && (
        <meta property="product:availability" content={ogStock || 'in stock'} />
      )}
      {/* OG:END */}
      {/* SEO_META:START */}
      {seo_meta.length > 0 &&
        seo_meta?.map((metaItem: any, index: number) => (
          <script
            key={index}
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: metaItem,
            }}
          />
        ))}
      {/* SEO_META:END */}
      {!!modifiedPreloadImage && (
        <link
          rel="preload"
          href={modifiedPreloadImage}
          as="image"
          fetchPriority="high"
        />
      )}
    </Head>
  )
}

export default MetaData
