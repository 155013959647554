import {AccordionContainer} from './Accordion.styles'
import {IAccordionProps} from './Accordion.interface'
import {BaseWidgetComponent} from '../BaseWidget'
import isEmpty from 'lodash/isEmpty'
import AccordionItem from './AccordionItem/AccordionItem'

function Accordion(props: IAccordionProps) {
  const {id, type, widgetData, header, layout} = props
  const {
    list,
    iconVariant = 'chevron',
    titleFontWeight = 'bold',
    isInitiallyOpen = false,
  } = widgetData || {}

  if (isEmpty(list)) {
    return null
  }

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <AccordionContainer>
        {list.map((item, index) => (
          <AccordionItem
            data={item}
            initiallyOpen={isInitiallyOpen}
            key={index}
            iconVariant={iconVariant}
            titleFontWeight={titleFontWeight}
          />
        ))}
      </AccordionContainer>
    </BaseWidgetComponent>
  )
}

export default Accordion
