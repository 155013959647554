import styled from 'styled-components'

export const StyledWidgetizedScreen = styled.div`
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 1400px;
    margin: auto;
    gap: 50px;
    > div {
      min-width: 0; // Prevents grid items from overflowing
    }
  }
  .right-column {
    padding-left: 50px;
  }
`
