import React, {FC, useCallback, useMemo} from 'react'
import {
  StyledBottomBorder,
  StyledTopBorder,
  StyledWidgetContainer,
} from './BaseWidget.styles'
import {BaseWidget} from '@mosaic-wellness/fe-types'
import {
  OptimizedImage,
  Typography,
  TypographyVariants,
  useDeviceTypeContext,
} from '@web-components'
import {useGenericActions} from '../../hooks'

interface BaseWidgetComponentProps {
  layout: BaseWidget['layout']
  customClassName?: string
  widgetType: string
  widgetId?: string
}

function BaseWidgetComponent({
  layout,
  children,
  customClassName = '',
  widgetType,
  widgetId = '',
}: React.PropsWithChildren<BaseWidgetComponentProps>) {
  const {
    type = 'CONTAINED',
    backgroundColor,
    verticalSpacing,
    backgroundImage,
    bottomBorder,
    isSupportedOnlyForMobile,
    borderRadius,
    background,
    topBorder = '',
    desktopFullWidth = false,
    isSupportedForDesktop = true,
  } = layout || {
    type: 'CONTAINED',
    backgroundColor: 'transparent',
    backgroundImage: '',
    verticalSpacing: {
      top: 'NONE',
      bottom: 'NONE',
    },
    bottomBorder: '',
    isSupportedOnlyForMobile: false,
    borderRadius: '0px',
    background: '',
    isSupportedForDesktop: true,
  }

  const computedStyles = useMemo(() => {
    return {
      ...(background && {background}),
      ...(backgroundColor && {backgroundColor}),
      ...(backgroundImage && {backgroundImage: `url(${backgroundImage})`}),
      ...(borderRadius && {borderRadius}),
    }
  }, [background, backgroundColor, backgroundImage, borderRadius])

  const doesHaveBackground = useMemo(() => {
    return (
      (backgroundColor !== 'transparent' && !!backgroundColor) || !!background
    )
  }, [background, backgroundColor])

  const layoutTypeClass = useMemo(() => {
    return `${type} VERTICAL_TOP_SPACING_${
      verticalSpacing?.top ?? 'NONE'
    } VERTICAL_BOTTOM_SPACING_${
      verticalSpacing?.bottom ?? 'NONE'
    } ${customClassName} ${
      isSupportedOnlyForMobile ? 'SUPPORTED_ONLY_FOR_MOBILE' : ''
    } ${doesHaveBackground ? 'ADJUST_SPACING_FOR_BACKGROUND' : ''}
      ${topBorder ? 'ADJUST_SPACING_FOR_TOP_BORDER' : ''}
      ${desktopFullWidth ? 'DESKTOP_FULL_WIDTH' : ''}
    ${isSupportedForDesktop ? 'SUPPORTED_FOR_DESKTOP' : ''}
    `
  }, [
    customClassName,
    doesHaveBackground,
    isSupportedOnlyForMobile,
    topBorder,
    type,
    verticalSpacing?.bottom,
    verticalSpacing?.top,
  ])

  return (
    <>
      <StyledWidgetContainer
        className={layoutTypeClass}
        style={computedStyles}
        data-widgettype={widgetType}
        data-widgetid={widgetId}
      >
        {topBorder ? (
          <StyledTopBorder
            data-isonlysupportedformobile={isSupportedOnlyForMobile}
          >
            <OptimizedImage
              source={topBorder}
              customClassName={`widget-top-border`}
              loading="lazy"
              aspectWidth="360"
              aspectHeight="18"
            />
          </StyledTopBorder>
        ) : null}
        {children}
      </StyledWidgetContainer>
      {bottomBorder ? (
        <StyledBottomBorder
          data-isonlysupportedformobile={isSupportedOnlyForMobile}
        >
          <OptimizedImage
            source={bottomBorder}
            customClassName={`widget-bottom-border`}
            altText="border"
            loading="lazy"
            aspectWidth="75"
            aspectHeight="1"
            desktopViewWidth="FULL"
            mobileViewWidth="FULL"
          />
        </StyledBottomBorder>
      ) : null}
    </>
  )
}

interface BaseWidgetTitleProps {
  title: NonNullable<BaseWidget['header']>['title']
  customClassName?: string
}
function BaseWidgetTitle({title, customClassName}: BaseWidgetTitleProps) {
  if (!title) {
    return null
  }

  return (
    <div className="widget-title-wrapper">
      <Typography
        variant={TypographyVariants.SUBHEADING_REGULAR}
        customClassName={`widget-title ${customClassName}`}
      >
        {title}
      </Typography>
    </div>
  )
}

BaseWidgetComponent.Title = BaseWidgetTitle

interface BaseWidgetSubtitleProps {
  subtitle: NonNullable<BaseWidget['header']>['subtitle']
  customClassName?: string
}

function BaseWidgetSubtitle({
  subtitle = '',
  customClassName = '',
}: BaseWidgetSubtitleProps) {
  if (!subtitle) {
    return null
  }
  return (
    <div className="widget-subtitle-wrapper">
      <Typography
        variant={TypographyVariants.SUBHEADING_REGULAR}
        customClassName={`widget-subtitle ${customClassName}`}
      >
        {subtitle}
      </Typography>
    </div>
  )
}

interface BaseWidgetHeaderProps {
  header: BaseWidget['header']
  customClassName?: string
  Component?: FC<any>
}

function BaseWidgetHeader({
  header,
  customClassName,
  Component,
}: BaseWidgetHeaderProps) {
  const {
    subtitle,
    title,
    label,
    textAlign = 'left',
    desktopTextAlign,
    titleType = 'NONE',
    subtileType = 'NONE',
    labelType = 'NONE',
    headingSubtitleGap = 16,
    cta,
  } = header || {title: '', subtitle: ''}

  const {actionName = '', actionData = {}, label: ctaLabel = ''} = cta || {}

  const {genericAction} = useGenericActions()

  const {isMobile} = useDeviceTypeContext()

  const handleCtaClick = useCallback(() => {
    genericAction({
      actionName: actionName,
      params: actionData,
    })
  }, [actionData, actionName, genericAction])

  const textAlignUsed = useMemo(
    () => (isMobile ? textAlign : desktopTextAlign || textAlign),
    [desktopTextAlign, isMobile, textAlign]
  )

  if (!title && !subtitle && !label) {
    return null
  }

  return (
    <div className="widget-header-container">
      <div
        className="widget-header"
        style={{
          gap: `${headingSubtitleGap}px` as any,
          textAlign: textAlignUsed as any,
        }}
      >
        <div className="title-container">
          {title ? (
            <div className="title" style={{textAlign: textAlign as any}}>
              <Typography
                variant={TypographyVariants.HEADING_SMALL_BOLD}
                customClassName={`widget-title ${titleType} ${customClassName}`}
                headingLevel={2}
              >
                {title}
              </Typography>
            </div>
          ) : null}
          {ctaLabel && (
            <div className="cta-container" onClick={handleCtaClick}>
              <Typography
                customClassName="ctaText"
                variant={TypographyVariants.CTA_LABEL_BIG}
              >
                {ctaLabel}
              </Typography>
            </div>
          )}
        </div>
        {subtitle ? (
          <Typography
            variant={TypographyVariants.BODY_BASE_REGULAR}
            customClassName={`widget-subtitle ${subtileType} ${customClassName}`}
          >
            {subtitle}
          </Typography>
        ) : null}
        {label && (
          <Typography
            variant={
              isMobile
                ? TypographyVariants.HEADING_LARGE_REGULAR
                : TypographyVariants.HEADING_2XLARGE_REGULAR
            }
            customClassName={`widget-title ${labelType} ${customClassName}`}
          >
            {label}
          </Typography>
        )}
      </div>
      {Component && <Component />}
    </div>
  )
}

BaseWidgetComponent.Title = BaseWidgetTitle
BaseWidgetComponent.Subtitle = BaseWidgetSubtitle
BaseWidgetComponent.Header = BaseWidgetHeader
export default BaseWidgetComponent
